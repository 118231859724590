<template>
  <div id="indiaPayment">
    <div class="content_box">
      <div class="inner_max">
        <div class="form_deposit">
          <h4>{{ $t('deposit.ida.header') }}</h4>
          <div class="info_box_choose" v-if="multiSelection">
            <el-button
              v-for="item in methodSelection"
              :key="item.name"
              @click="handleSelectIndex(item.value, item.name)"
              :class="elButtonClassName(paymentChannel === item.value)"
              :data-testid="item.label"
            >
              {{ item.label }}<br />
            </el-button>
          </div>
          <component :is="currentComponent" ref="child"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paygate from '@/components/deposit/India/Paygate';
import Dasshpe from '@/components/deposit/India/DasshpeNetBanking';
import multiplePaymentMixin from '@/mixins/page/deposit/multiplePayment';

export default {
  name: 'IndiaPayment',
  components: { Paygate, Dasshpe },
  mixins: [multiplePaymentMixin],
  data() {
    return {
      paymentChannel: null,
      currentComponent: null,
      methodSelection: [],
      inCountryCode: 6845,
      multiSelection: false
    };
  },
  mounted() {
    this.methodSelection = this.$platform.getDepositBankTransferSelection(this.regulator)[this.inCountryCode];

    if (this.methodSelection.length > 1) {
      this.multiSelection = true;
    }
    let index = this.methodSelection.map(item => item.default).indexOf(true);
    this.paymentChannel = this.methodSelection[index].value;
    this.currentComponent = this.methodSelection[index].name;
  },
  methods: {
    handleSelectIndex(i, n) {
      this.paymentChannel = i;
      this.currentComponent = n;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
@import '@/assets/css/components/deposit/multipleButton.scss';
</style>
