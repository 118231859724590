<template>
  <div class="content">
    <div class="info_box">
      <img src="@/assets/images/channel/local_transfer.png" alt="" />
      <p>{{ $t('deposit.method.desc', { method: $t('deposit.ida.header') }) }}</p>
      <ul>
        <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
        <li>{{ $t('deposit.method.inst3', { number: '2' }) }}</li>
      </ul>
    </div>
    <div class="form_main">
      <p class="title">{{ $t('deposit.method.form.header', { method: $t('deposit.ida.header') }) }}</p>
      <el-form label-position="top" :model="dasshpeForm" ref="dasshpeForm" :rules="dasshpeRules" status-icon>
        <div class="form_box">
          <ul class="clearfix">
            <li class="fl">
              <AccountNumber
                supportedCurrencies="USD"
                @setCurrency="setCurrency"
                @setAccountNumber="setAccountNumber"
              ></AccountNumber>
            </li>
            <li class="fr">
              <el-form-item :label="$t('common.field.amt')" prop="amount">
                <numeric-input v-model="dasshpeForm.amount" :currency="accountCurrency" :precision="2"></numeric-input>
              </el-form-item>
            </li>
          </ul>
          <ul class="clearfix">
            <li class="fl">
              <el-form-item :label="$t('common.field.bankName')" prop="mopCode">
                <el-select v-model="dasshpeForm.mopCode" data-testid="BankList">
                  <el-option
                    v-for="(value, name) in bankList"
                    :key="name"
                    :value="name"
                    :label="value"
                    :data-testid="value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </li>
            <li class="fr">
              <el-form-item :label="$t('common.field.imptNotes')">
                <el-input v-model="dasshpeForm.notes" data-testid="applicationNotes"></el-input>
              </el-form-item>
            </li>
          </ul>
          <ul class="clearfix">
            <li class="fl">
              <p class="mb-2">
                <span class="required_icon">*</span>
                {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'INR' }) }}
                <span>{{ rate.toLocaleString(undefined, { minimumFractionDigits: 2 }) }}</span>
              </p>
              <p>
                <span class="required_icon">*</span> INR: <span>{{ rateChange }}</span>
              </p>
            </li>
          </ul>
        </div>
        <el-button type="primary" :loading="loading" @click="submitForm()" data-testid="submit">
          {{ $t('common.button.submit') }}
        </el-button>
      </el-form>
      <div v-html="bankHTML"></div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import mixin from '@/mixins/page/deposit';
import indiaMixin from '@/mixins/page/deposit/india';
import rounding from '@/util/rounding';
import { autoForm } from '@/util/getAutoForm';
import { apiDasshpeNB_payment, apiDasshpeNB_banklist } from '@/resource';

export default {
  name: 'IndiaDasshpeNetBanking',
  components: { NumericInput, AccountNumber },
  mixins: [mixin, indiaMixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (this.rateChange > this.maxLimit) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxUSD, currency: this.accountCurrency }))
        );
      } else {
        callback();
      }
    };
    return {
      dasshpeForm: {
        accountNumber: '',
        amount: '',
        mopCode: null,
        notes: ''
      },
      dasshpeRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        mopCode: {
          required: true,
          message: this.$t('common.formValidation.dynamicReq', { dynamic: this.$t('common.field.bankName') }),
          trigger: 'blur'
        }
      },
      bankHTML: '',
      maxLimit: 200000,
      bankList: [],
      paymentChannel: 5
    };
  },
  computed: {
    rateChange() {
      return rounding(Math.ceil, this.rate * this.dasshpeForm.amount, 2);
    },
    maxUSD() {
      return rounding(Math.floor, this.maxLimit / this.rate, 2);
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.dasshpeForm.accountNumber = accountNumber;
    },
    success(msg) {
      this.bankHTML = autoForm(msg);
      if (this.bankHTML.length > 0) {
        this.$nextTick(function () {
          if (document.getElementById('AutoForm')) {
            document.getElementById('AutoForm').submit();
          } else {
            this.loading = false;
            this.errorMessage(this.$t('deposit.default.failed'));
          }
        });
      } else {
        this.loading = false;
        this.errorMessage(this.$t('deposit.default.failed'));
      }
    },
    submitForm() {
      console.log(this.dasshpeForm.mopCode);
      this.$refs['dasshpeForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              this.depositSubmitWithRate(result, this.success);
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    getBankList() {
      apiDasshpeNB_banklist()
        .then(resp => {
          if (resp.data.code == 0) {
            this.bankList = resp.data.data;
          }
        })
        .catch(err => {
          this.loading = false;
          this.errorMessage(this.$t('deposit.default.failed'));
        });
    },
    submitDeposit() {
      return apiDasshpeNB_payment(
        {
          mt4Account: this.dasshpeForm.accountNumber,
          operateAmount: this.dasshpeForm.amount,
          mopCode: this.dasshpeForm.mopCode,
          depositAmount: this.rateChange,
          rate: this.rate,
          paymentChannel: this.paymentChannel,
          applicationNotes: this.dasshpeForm.notes
        },
        this.token
      );
    }
  },
  mounted() {
    this.getBankList();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
