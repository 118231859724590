<template>
  <div class="content">
    <div class="info_box">
      <img src="@/assets/images/channel/local_transfer.png" alt="" />
      <p>{{ $t('deposit.method.desc', { method: $t('deposit.ida.header') }) }}</p>
      <ul>
        <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
        <li>{{ $t('deposit.method.inst2', { number: '2', country: $t('deposit.ida.country') }) }}</li>
        <li>{{ $t('deposit.method.inst3', { number: '3' }) }}</li>
      </ul>
    </div>
    <div class="form_main">
      <p class="title">{{ $t('deposit.method.form.header', { method: $t('deposit.ida.header') }) }}</p>
      <el-form label-position="top" :model="zotaForm" ref="zotaForm" status-icon :rules="zotaRules">
        <div class="form_box">
          <ul class="clearfix">
            <li class="fl">
              <AccountNumber
                supportedCurrencies="USD"
                @setCurrency="setCurrency"
                @setAccountNumber="setAccountNumber"
              ></AccountNumber>
            </li>
            <li class="fr">
              <el-form-item :label="$t('common.field.amt')" prop="amount">
                <numeric-input v-model="zotaForm.amount" :currency="accountCurrency" :precision="2"></numeric-input>
              </el-form-item>
            </li>
          </ul>
          <ul class="clearfix">
            <li class="fl">
              <el-form-item :label="$t('common.field.imptNotes')">
                <el-input v-model="zotaForm.notes" data-testid="applicationNotes"></el-input>
              </el-form-item>
            </li>
            <li class="fr">
              <p class="mb-2">
                <span class="required_icon">*</span>
                {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'INR' }) }}
                <span>{{ rate.toLocaleString(undefined, { minimumFractionDigits: 2 }) }}</span>
              </p>
              <p class="mb-2">
                <span class="required_icon">*</span> INR: <span>{{ rateChange }}</span>
              </p>
              <p>
                <span class="required_icon invisible">*</span>
                {{ $t('common.field.maximumAmountTip', { amount: '50,000', currency: 'INR' }) }}
              </p>
            </li>
          </ul>
        </div>
        <el-button type="primary" :loading="loading" @click="submitForm()" data-testid="submit">
          {{ $t('common.button.submit') }}
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import mixin from '@/mixins/page/deposit';
import indiaMixin from '@/mixins/page/deposit/india';
import zotapayMixin from '@/mixins/page/deposit/zotapay';
import { apiIndia_bankwire_payment } from '@/resource';

export default {
  name: 'IndiaPaygate',
  components: { NumericInput, AccountNumber },
  mixins: [mixin, indiaMixin, zotapayMixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (this.rateChange > this.maxLimit) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxUSD, currency: this.accountCurrency }))
        );
      } else {
        callback();
      }
    };
    return {
      zotaRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      },
      maxLimit: 50000,
      paymentChannel: 1
    };
  },
  methods: {
    submitDeposit() {
      return apiIndia_bankwire_payment(
        {
          mt4Account: this.zotaForm.accountNumber,
          operateAmount: this.zotaForm.amount,
          applicationNotes: this.zotaForm.notes,
          depositAmount: this.rateChange,
          rate: this.rate,
          paymentChannel: this.paymentChannel
        },
        this.token
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
